import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const GroupsRFPForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form id="groups-rfp" onSubmit={(e) => processForm(e, 'buk_5Z2EaoIQ7gpaHpU2e29aXRvp', null, captchaRef)}>
      <h2>Group Information</h2>
      <h5>* = Required Field</h5>
      <h4>Organization *</h4>
      <input className="form-control" id="organization" name="organization" type="text" required="" />
      <h4>Contact Name *</h4>
      <input className="form-control" id="contactName" name="contactName" type="text" required="" />
      <h4>Email address *</h4>
      <input className="form-control" id="email" name="email" type="email" required="" />
      <h4>Phone Number*</h4>
      <input className="form-control" id="phoneNumber" name="phoneNumber" type="tel" required="" />
      <h4>Arrival Date *</h4>
      <input className="form-control" id="arrivalDate" name="arrivalDate" type="date" required="" />
      <h4>Departure Date *</h4>
      <input className="form-control" id="departureDate" name="departureDate" type="date" required="" />
      <h4>Number of Attendees *</h4>
      <select className="form-control" id="numberAttendees" name="numberAttendees" required="">
        <option selected="selected" value="">- select -</option>
        <option value="1-30">1-30</option>
        <option value="31-50">31-50</option>
        <option value="51-75">51-75</option>
        <option value="76-100">76-100</option>
        <option value="101+">101+</option>
      </select>
      <h4>Group Type *</h4>
      <select className="form-control" id="groupType" name="groupType" required="">
        <option selected="selected" value="">- select -</option>
        <option value="Ski Club/Tour Operator">Ski Club/Tour Operator</option>
        <option value="Wedding">Wedding</option>
        <option value="College/K-12/Youth">College/K-12/Youth</option>
        <option value="Social">Social</option>
        <option value="Business/Corporate">Business/Corporate</option>
        <option value="Other">Other</option>
      </select>
      <hr />
      <h2>Recreation Activities:</h2>
      <h4>Winter Activities (Select all that apply) *:</h4>
      <ul className="l-group">
        <li className="l-item">
          <input className="checkbox" id="skiSnowLift" name="skiSnowLift" type="checkbox" value="Ski / Snowboard / Lift Tickets" />
          <label htmlFor="skiSnowLift">Ski / Snowboard / Lift Tickets</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="snowblastTubing" name="snowblastTubing" type="checkbox" value="Snowblast Tubing" />
          <label htmlFor="snowblastTubing">Snowblast Tubing</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="nordic" name="nordic" type="checkbox" value="Nordic" />
          <label htmlFor="nordic">Nordic</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="rentals" name="rentals" type="checkbox" value="Rentals" />
          <label htmlFor="rentals">Rentals</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="lessons" name="lessons" type="checkbox" value="Lessons" />
          <label htmlFor="lessons">Lessons</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="proMountainTour" name="proMountainTour" type="checkbox" value="Pro Mountain Tour" />
          <label htmlFor="proMountainTour">Pro Mountain Tour</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="foodBeverage" name="foodBeverage" type="checkbox" value="Food &amp; Beverage Event" />
          <label htmlFor="foodBeverage">Food & Beverage Event</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="sledDogRide" name="sledDogRide" type="checkbox" value="Sled Dog Rides" />
          <label htmlFor="sledDogRide">Sled Dog Rides</label>
        </li>
      </ul>
      <h4>Summer Activities (Select all that apply) *:</h4>
      <ul className="l-group">
        <li className="l-item">
          <input className="checkbox" id="privateDinner" name="privateDinner" type="checkbox" value="Private Dinner Event / Sunset Dinners" />
          <label htmlFor="privateDinner">Private Dinner Event / Sunset Diners</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="scenicChairlift" name="scenicChairLift" type="checkbox" value="Scenic Chairlift" />
          <label htmlFor="scenicChairLift">Scenic Chairlift</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="whitewaterRafting" name="whitewaterRafting" type="checkbox" value="Whitewater Rafting" />
          <label htmlFor="whitewaterRafting">Whitewater Rafting</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="zipline" name="zipline" type="checkbox" value="Zip Line" />
          <label htmlFor="zipline">Zip Line</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="wrecktangle" name="wrecktangle" type="checkbox" value="WreckTangle" />
          <label htmlFor="wrecktangle">WreckTangle</label>
        </li>
      </ul>
      <hr />
      <h2>Meetings, Weddings & Events Information:</h2>
      <h4>Lodging Needed (Yes/No). If Yes, # of nights. *</h4>
      <textarea id="lodgingNeeded" rows="9" cols="33" name="lodgingNeeded" required="" />
      <h4>Transportation (Type and # of Vehicles) *</h4>
      <textarea id="transportation" rows="9" cols="33" name="transportation" required="" />
      <h4>Additional Comments</h4>
      <textarea id="additionalComments" rows="9" cols="33" name="additionalComments" />

      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />

      <button type="submit" value="Submit" disabled={!isUserVerified}>{buttonText || ButtonText.SUBMIT}</button>
    </form>
  );
};

GroupsRFPForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

GroupsRFPForm.defaultProps = {
  buttonText: null,
};
