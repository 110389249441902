import { ThemeProps as Prop, StaticProfiles } from '@powdr/constants';

export const StaticTheme = {
  colors: {
    [Prop.BLACK]: '#000000',
    [Prop.WHITE]: '#FFFFFF',
  },
  shades: {
    [Prop.LIGHTEST]: '#f0f0f0',
    [Prop.LIGHTER]: '#E0E0E0',
    [Prop.LIGHT]: '#A0ADAD',
    [Prop.GRAY]: '#909D9D',
    [Prop.GRAYER]: '#6F7C7C',
    [Prop.GRAYEST]: '#4D585B',
    [Prop.DARK]: '#333333',
    [Prop.DARKER]: '#222222',
    [Prop.DARKEST]: '#292929',
  },
  difficulty: {
    [Prop.BEGINNER]: '#73C053',
    [Prop.INTERMEDIATE]: '#30A8E0',
    [Prop.EXPERT]: '#000000',
  },
  social: {
    [Prop.FACEBOOK]: '#3B5998',
    [Prop.EVENTBRITE]: '#F6682F',
  },
  alerts: {
    [Prop.ACTIVE]: '#2176b8',
    [Prop.SUCCESS]: '#73c053',
    [Prop.INFO]: '#0b24fb',
    [Prop.WARNING]: '#FFB400',
    [Prop.DANGER]: '#FE4A49',
    [Prop.ALERT_WARNING_BG]: '#FFB400',
    [Prop.ALERT_WARNING_CONTENT]: '#000000',
    [Prop.ALERT_DANGER_BG]: '#FE4A49',
    [Prop.ALERT_DANGER_CONTENT]: '#FFFFFF',
  },
  //
  parkingCal: {
    [Prop.SECTION_BG]: '#f0f0f0',
    [Prop.SECTION_TXT]: '#116C92',
    [Prop.ITEM_BG]: '#DCDCDC',
    [Prop.ITEM_TXT]: '#116C92',
    [Prop.ITEM_TXT_HOVER]: '#00A1E4',
    [Prop.ITEM_BG_NO_INTERACT]: '#ECECEC',
    [Prop.ITEM_TXT_NO_INTERACT]: '#BED4DC',
    [Prop.ITEM_BG_AVAILABLE]: '#00A1E4',
    [Prop.ITEM_TXT_AVAILABLE]: '#FFFFFF',
    [Prop.ITEM_BTN_BG_AVAILABLE]: '#FFD200',
    [Prop.ITEM_BTN_TXT_AVAILABLE]: '#116C92',
    [Prop.ITEM_BG_SELECTED]: '#116C92',
    [Prop.ITEM_TXT_SELECTED]: '#FFFFFF',
    [Prop.ITEM_BTN_BG_SELECTED]: '#00A1E4',
    [Prop.ITEM_BTN_TXT_SELECTED]: '#FFFFFF',
    [Prop.RES_ITEM_CONTAINER_BG]: '#FFFFFF',
  },
  [StaticProfiles.TV]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#f74d1e',
    [Prop.CONTENT]: '#000000',
    [Prop.BORDER]: '#FFFFFF',
    [Prop.ICON]: '#F74D1E',
    [Prop.LINK]: '#F74D1E',
    [Prop.LINK_HOVER]: '#28757B',
    [Prop.ODD_EVEN_OFFSET]: '#D3D3D3',
    [Prop.SIDE_NAV_ITEM_BG]: '#383C3F',
    [Prop.SIDE_NAV_ITEM_TXT]: '#8CDEE0',
    [Prop.SIDE_NAV_ITEM_ICON]: '#8CDEE0',
    [Prop.SIDE_NAV_ITEM_BG_ACTIVE]: '#F74D1E',
    [Prop.SIDE_NAV_ITEM_TXT_ACTIVE]: '#FFFFFF',
    [Prop.SIDE_NAV_ITEM_ICON_ACTIVE]: '#FFFFFF',
    [Prop.TOP_NAV_ITEM_TXT]: '#f74d1e',
  },
};
