import { ThemeProps as Prop, ColorProfiles } from '@powdr/constants';

import { componentThemesDefault } from './shared-theme-data';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#F74D1E',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#F74D1E',
    [Prop.LINK_TXT_HOVER]: '#28757B',
    [Prop.BTN_BG]: '#383C3F',
    [Prop.BTN_TXT]: '#8CDEE0',
    [Prop.BTN_BG_HOVER]: '#28757B',
    [Prop.BTN_TXT_HOVER]: '#8CDEE0',
    [Prop.BTN_BG_ACTIVE]: '#F74D1E',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#D3D3D3',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#8CDEE0',
    [Prop.ELEMENT_TXT]: '#383C3F',
    [Prop.BORDER]: '#D3D3D3',
    [Prop.ICON]: '#F74D1E',
    [Prop.ICON_HOVER]: '#28757B',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#28757B',
    [Prop.HEADER]: '#8CDEE0',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#F74D1E',
    [Prop.LINK_TXT_HOVER]: '#8CDEE0',
    [Prop.BTN_BG]: '#8CDEE0',
    [Prop.BTN_TXT]: '#383C3F',
    [Prop.BTN_BG_HOVER]: '#383C3F',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#F74D1E',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#D3D3D3',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#8CDEE0',
    [Prop.ELEMENT_TXT]: '#383C3F',
    [Prop.BORDER]: '#D3D3D3',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#8CDEE0',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#8CDEE0',
    [Prop.HEADER]: '#383C3F',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#F74D1E',
    [Prop.LINK_TXT_HOVER]: '#28757B',
    [Prop.BTN_BG]: '#FFFFFF',
    [Prop.BTN_TXT]: '#F74D1E',
    [Prop.BTN_BG_HOVER]: '#28757B',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#F74D1E',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#D3D3D3',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#FFFFFF',
    [Prop.ELEMENT_TXT]: '#F74D1E',
    [Prop.BORDER]: '#28757B',
    [Prop.ICON]: '#F74D1E',
    [Prop.ICON_HOVER]: '#28757B',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#EDEDEF',
    [Prop.HEADER]: '#F74D1E',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#F74D1E',
    [Prop.LINK_TXT_HOVER]: '#28757B',
    [Prop.BTN_BG]: '#28757B',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#8CDEE0',
    [Prop.BTN_TXT_HOVER]: '#383C3F',
    [Prop.BTN_BG_ACTIVE]: '#F74D1E',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#D3D3D3',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#8CDEE0',
    [Prop.ELEMENT_TXT]: '#383C3F',
    [Prop.BORDER]: '#D3D3D3',
    [Prop.ICON]: '#F74D1E',
    [Prop.ICON_HOVER]: '#28757B',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#383C3F',
    [Prop.HEADER]: '#8CDEE0',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#F74D1E',
    [Prop.LINK_TXT_HOVER]: '#8CDEE0',
    [Prop.BTN_BG]: '#8CDEE0',
    [Prop.BTN_TXT]: '#383C3F',
    [Prop.BTN_BG_HOVER]: '#D3D3D3',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#F74D1E',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#D3D3D3',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#8CDEE0',
    [Prop.ELEMENT_TXT]: '#383C3F',
    [Prop.BORDER]: '#D3D3D3',
    [Prop.ICON]: '#F74D1E',
    [Prop.ICON_HOVER]: '#8CDEE0',
  },
  ...componentThemesDefault,
};
